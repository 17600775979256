<div class="wrapp">
   <div  class="card" *ngFor="let cards of this.item.cards">
      <img class="icon" *ngIf="cards.image!=undefined" [attr.src]="cards.image"/>
      <div *ngIf="cards.text != undefined"> 
         <h3 class="card-title" >{{cards.text}}</h3>
         <p class="card-discription" >{{cards.discription}}</p>      
      </div>   
     
         <app-button ngSkipHydration class="button"  [class.noactive]="cards.titleLink==''" (click)="this.onClickButton()">{{cards.titleLink}}</app-button>  
         <app-button ngSkipHydration class="button"  [class.noactive]="cards.titleLink2==''" (click)="this.onClickButton2()">{{cards.titleLink2}}</app-button>
         <app-button ngSkipHydration class="button"  [class.noactive]="cards.titleLink3==''" (click)="this.onClickButton3()">{{cards.titleLink3}}</app-button>
   </div>
</div>    