import { Component, Input } from '@angular/core';
import { SiteService } from '../../site.service';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-element-product',
  templateUrl: './element-product.component.html',
  styleUrls: ['./element-product.component.scss']
})
export class ElementProductComponent {
  @Input() item:any = {}; 
  open:any = false;

  constructor(public site: SiteService, private router: Router, private route: ActivatedRoute, ){}

  checkDescription(){
    this.open = !this.open;
  }
 
  openProduct(item:any){
    this.router.navigate([], {
      queryParams: {
        id: item.id
      },
      queryParamsHandling: 'merge',
    });
  }
}
