import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router'; 
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';

@Component({
  selector: 'app-equipment-customization-kkm',
  templateUrl: './equipment-customization-kkm.component.html',
  styleUrls: ['./equipment-customization-kkm.component.scss']
})
export class EquipmentCustomizationKkmComponent {
  currentPacket: any = [];
  activePacket:any = 0;
  page:any = {};
  bgOpen = ''; 
  bgOpenTwo = ''; 

  constructor(public site:SiteService, private route: ActivatedRoute, private openFormService: openFormService, private openFormData: openFormData){

  }

  ngOnInit(): void {   
    this.currentPacket = this.site.packages.training;
    this.page = this.site.GetCurrentPage();

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
    });

    this.openFormData.backgroundChanged.subscribe(() => {
      this.bgOpenTwo = this.bgOpenTwo  ? '' : 'bg-open2'; // Меняем класс
    });
  }

  ngAfterContentInit(){
  }

  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
