import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class openFormData {
private _backgroundChangedSubject = new Subject<void>();
  public backgroundChanged = this._backgroundChangedSubject.asObservable();


  constructor() { }

  backgroundTopTwo() {
    this._backgroundChangedSubject.next(); // Отправляем сигнал о необходимости сменить фон
  }

}