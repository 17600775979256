import { Component, Input } from '@angular/core';
import { openFormData } from '../../servises/openFormData.service';
import { openFormService } from '../../servises/openForm.service';

@Component({ 
  selector: 'app-paralel-scroll',
  templateUrl: './paralel-scroll.component.html',
  styleUrls: ['./paralel-scroll.component.scss']
})
export class ParalelScrollComponent {
  @Input() item: any = {};
 constructor( private openFormData: openFormData, private openFormService: openFormService){}


   onClickButton() {
     this.openFormService.backgroundTop2(); // Вызываем метод сервиса для изменения фона
   }
}
 