<div class="wrapper">
     
     <div class="button">
        <app-mini-grin-arrow  (onClick)="($event == 1) ? this.productNext() : this.productPrev()">
        </app-mini-grin-arrow > 
     </div>
     
    <app-slider [buttonsMobile]="true" [type]="this.animationType" [duration]="this.animationDuration" >

        <app-slide class="slide" *ngFor="let item of this.site.elements.elFullWithSlider">
  
         <app-element-full-w-slider [item]="item" style="height:max-content"></app-element-full-w-slider>
             
         </app-slide>   
           

    </app-slider>





</div>
