<div *ngIf="this.small == false" class="buttons ">
    <button class="button-left pointer arrow left" (click)="this.productPrev()">
        <svg class="with-button"  viewBox="0 0 50 80" xml:space="preserve">
          <polyline fill="none" stroke="#FFFFFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" points="
          45.63,75.8 0.375,38.087 45.63,0.375 "/>
        </svg>    
    </button>
    <button class="button-right pointer arrow right" (click)="this.productNext()">
        <svg class="with-button"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 50 80" xml:space="preserve">
          <polyline fill="none" stroke="#FFFFFF" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" points="
          0.375,0.375 45.63,38.087 0.375,75.8 "/>
        </svg>
    </button>
</div>

<div *ngIf="this.small == true" class="buttons  buttons-small">
    <button class="button-left pointer arrow left small" (click)="this.productPrev()">
        <svg width="15px" height="20px" viewBox="0 0 50 80" xml:space="preserve">
          <polyline fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="
          45.63,75.8 0.375,38.087 45.63,0.375 "/>
        </svg>  
    </button>
    <button class="button-right pointer arrow right small" (click)="this.productNext()">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="20px" viewBox="0 0 50 80" xml:space="preserve">
          <polyline fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="
          0.375,0.375 45.63,38.087 0.375,75.8 "/>
        </svg>
    </button>
</div> 
