<div  class="wrapper" *ngIf="this.isClose==false">
 
    <div class="form-lower">
        <div class="pop-up-form-hidden__left">
            <div class="left__top">
                <img class="left-logo" src="assets/header/logo.png"/>
                <div class="contenier-top-title">
                   <h3 class="top-title">TripAdvance</h3>
                   <p class="top-subtitle">Официальный Сайт ООО "Трип-Адванс"</p>
                </div>
             </div>
        </div>
        <div class="pop-up-form-hidden__riht" (click)="this.close()">
            <div class="form-close"  ></div>
            <img class="hidden-riht-img" src="assets/forms/stars.svg"/>
            <div class="hidden-riht-top">
                <h3 class="riht__titel riht__titel-hidden">Мы Ценим Ваше Доверие!</h3>
                <p class="riht__subtitel">Мы свяжемся с вами в ближайшее время</p>
             </div>
             <div class="hidden-riht-center">
                <p class="riht__subtitel">+7 (861) 213-23-13</p>
                <p class="riht__subtitel">Наша техподдрежка</p>
           </div>
           <div class="hidden-riht-bottom">
             <p class="form-hodden__discription"><span class="form-hodden__line">&mdash;</span>С уважением Tripadvance<span class="form-hodden__line2">&mdash;</span></p>
           </div>
           <div class="hidden-riht-bottom1" >
               <div class="bg-top">
                  <p class="bottom__title" >Данные получены</p>
                  <img class="bottom__icon" src="assets/forms/check-tick.svg" />
               </div>
               <div class="bg-red" [class]="backgroundClass">

               </div>
           </div>
        </div>
      
    </div>

</div>

