import { Component, OnInit  } from '@angular/core';
import { ToggleService } from '../../servises/toggle.service';
import { openClassService } from '../../servises/openClass.service';

@Component({
  selector: 'app-modal-forms-lower', 
  templateUrl: './modal-forms-lower.component.html',
  styleUrls: ['./modal-forms-lower.component.scss']
}) 
export class ModalFormsLowerComponent implements OnInit {
  showDiv = false;
  backgroundClass = '';


  constructor(private toggleService: ToggleService) {}
  ngOnInit(): void {
    this.toggleService.redAnimationChanged.subscribe(() => {
      this.backgroundClass = this.backgroundClass ? '' : 'bg-red-animation'; // Меняем класс
    });
  }


  public isClose = false;
  close(){
    this.isClose = true;
  }
}
