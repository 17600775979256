<div class="wrapp-form">
    <div class="left">

        <div class="wrapp-forms">
           <div class="form-visible">
              <form class="left__form">

                <div class="control" (click)="this.onChange(item)" > 
                  <app-input-phone class="input-title-t" [value]="this.phone" (onChange)="this.phone = $event"  [required]="true"></app-input-phone>
                  <div class="icon-title-cont" [class.title-active]="item.top==true">
                    <div class="icon icon-img"  [class.icon-active]="item.top==true"> </div>
                    <label class="icon-title icon1" for="name" >Введите телефон</label>
                  </div>
                </div> 
                <div class="control" (click)="this.onChangeTwo(item2)" >
                   <input type="text" id="name" class="input-title"  required>
                   <div class="icon-title-cont" [class.title-active]="item2.topTwo==true">
                      <div class="icon icon-img1"  [class.icon-active1]="item2.topTwo==true"> </div>
                      <label class="icon-title icon1" for="name" >Введите ваше имя</label>
                   </div>
                </div>

              </form> 
              <div class="left__chexbox-cont">
               <app-checked-foms></app-checked-foms>
                  <a href="politics-confendiality" class="left__chexbox-title">Нажатием кнопки я принимаю Условия оферты по использованию сайта и соглашаюсь с Политикой конфиденциальности</a>  
              </div>
              <div class="cont-button">
        
              <app-button-full-with (click)="toggleClass()">ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</app-button-full-with>
           </div>

           </div> 

        <div class="form-hidden" [class.button-open]="isActive" #targetElement>

            <img class="form-icon" src="assets/forms/stars.svg"/>  
            <h3 class="form-hodden__title">Мы ценим ваше доверие!</h3>
            <p class="form-hodden__subtitle">Мы свяжемся с вами в самое ближайшее время</p>
            <p class="form-hodden__subtitle">+7 (861) 213-23-13</p>
            <p class="form-hodden__subtitle">Наша техподдержка</p>
            <p class="form-hodden__discription"><span class="form-hodden__line">&mdash;</span>С уважением Tripadvance<span class="form-hodden__line2">&mdash;</span></p>
            <div class="wrapper-btn"  >
               <div class="bg-red" [class.button-open]="isActive" #targetElement>
                 
               </div>
               <div class="tile-btn">
                 <p class="bottom__title" >Данные получены</p>
                 <img class="bottom__icon" src="assets/forms/check-tick.svg" />
               </div>
            </div>

        </div> 
       </div>

    </div>
    <div class="riht">
        <div class="riht-top">
            <h3 class="riht__title">ПРЕДОСТАВЛЯЕМ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ</h3>
            <p class="riht__subtitle">Квалифицированный специалист, отдельного направления предложит решение</p>
        </div>
        <div class="riht-bottom">
        </div>
    </div>
</div>

