import { Component, Input } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';

@Component({
  selector: 'app-marcetong-cards-two',
  templateUrl: './marcetong-cards-two.component.html',
  styleUrls: ['./marcetong-cards-two.component.scss']
})
export class MarcetongCardsTwoComponent {
  @Input() item:any = {};

  constructor( private openFormService: openFormService, private openFormData: openFormData){}
  
    onClickButton() {
      this.openFormService.backgroundTop(); // Вызываем метод сервиса для изменения фона
   } 
}
  