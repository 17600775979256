import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ViewChildren, ContentChildren, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { SliderAnimationType } from '../../ui/slider/slider.enum';
import { SliderComponent } from '../../ui/slider/slider.component';
import { SlideComponent } from '../../ui/slide/slide.component';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { SiteService } from '../../site.service';
 
@Component({
  selector: 'app-packets-mobile', 
  templateUrl: './packets-mobile.component.html',
  styleUrls: ['./packets-mobile.component.scss']
})
export class PacketsMobileComponent {
  @Input() item:any = {}; 
  @Input() sizeTitle:any = 18;
  @Input() height:any = "auto"; 
  @Input() padding:any = "6px 24px 0px 24px";
  //
  public currentSlide: number = 0; 
  public countSlides: number = 3;
  public slides: string[] = [];
  public page: any = {};

  @ViewChild(SliderComponent) slider!: SliderComponent;
  @ContentChildren(SlideComponent, { read: ElementRef }) elSliders!: any;

  

  public paginatePage: number = 0;
  @ViewChild("progressBar", { static: false }) progressBar!: ElementRef;
  
  public animationType: SliderAnimationType = SliderAnimationType.EaseInOutSine;
  public animationDuration: number = 500;

  constructor(public site: SiteService){}

  ngOnInit() {
    for (let i = 0; i < this.countSlides; i++) {
      this.slides.push(`Slide ${i + 1}`);
    }
    
    // Убедимся, что количество слайдов кратно трем
    if (this.countSlides % 3 !== 0) {
      const missingSlides = 3 - (this.countSlides % 3);
      for (let i = 0; i < missingSlides; i++) {
        this.slides.push('');
      }
      this.countSlides += missingSlides;
    }
    this.page = this.site.GetCurrentPage ()
  
  }

  ngAfterViewInit(): void {
  
    this.countSlides = this.elSliders.length;
  }

  ngAfterContentInit(): void {}

  getCurrentSlide() {
    return this.currentSlide;
  }

  getCountSlides() {
    return this.countSlides;
  }

  getCounterColor(paginatedIndex: number) {
    const realIndex = this.getPaginatedIndex(paginatedIndex);
    return {
      'active': this.currentSlide === realIndex,
      'inactive': this.currentSlide !== realIndex
    };
  }

  getPaginatedIndex(paginatedIndex: number): number {
    return this.paginatePage * 3 + paginatedIndex;
  }
 
  updatePagination(currentSlide: number) {
    this.paginatePage = Math.floor(currentSlide / 3);
  }

  changeSlide(event: any) {
    this.currentSlide = event.current;
    this.updatePagination(this.currentSlide);
  }

  setBaner(value: any) {
    this.slider.setSlide(value);
  }
}
