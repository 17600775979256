<div class="wrapper">



    <div class="wrapper-discription">
        <div class="contents">
           <div class="contenier-content">
               <img src="../../assets/main/Logo red.svg" class="footer-logo"/>
               <p class="discription__link">TRIPADVANCE</p>
           </div>
           <div class="contenier-content__discription">
              <p class="discription">Мы стремимся к сотрудничеству с ведущими поставщиками решений в сфере путешествий и технологий объединив разработки и продукты, чтобы расширить возможности отельеров по всему миру</p>
              <p class="discription" style="margin-top: 20px;">С уважением! Команда компании Tripadvance</p>  
           </div>
        </div>
    </div>

    <div class="footer-menu">
        <app-footer-menu ></app-footer-menu>
    </div>   

    <div class="wrapper-content">
        <div class="wrapper-content__left">
            <div class="wrapper-content__column">
              <a class="content-left__text content-company">Общество с ограниченной ответственностью<br>"ТРИП-АДВАНС"</a>
              <a class="content-left__text content-inn">ИНН 2301105561</a>
              <a class="content-left__text content-phone">+7 (861) 213-23-13</a>
              <a class="content-left__text content-email"> acc<img src="../../assets/header/email-svgrepo-com.svg" style="width: 1.2vw;margin-bottom: -3px;"/>tripadvance.ru</a>
              <a class="content-left__text content-address">353435 Краснодарский край</a>
              <a class="content-left__text content-address">г.о. город-курорт Анапа г. Анапа</a>
              <a class="content-left__text content-address">х. Усатова Балка</a>
              <a class="content-left__text content-address">ул. Красная д. 1/9 кв. 3</a>
           </div>
           <div class="wrapper-content__column">
              <a href="politics-confendiality" class="content-left__text content-personal">Условия использование персональных данных</a>
              <a class="content-left__text content-personal">Условия бронирования (модуль Tripadvance)</a>
              <div class="icon-contenier">
                <a href="https://vk.com/write-178404947" class="icon">
                    <img  src="assets/footer/Vector01.svg" class="icon__img"/>
                </a>
                <a href="https://t.me/tripadvancesupport_bot" class="icon">
                    <img  src="assets/footer/Vector02.svg" class="icon__img"/>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=79114600190&text=%D0%9F%D0%BE%D0%B6%D0%B0%D0%BB%D1%83%D0%B9%D1%81%D1%82%D0%B0%2C%20%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D1%8D%D1%82%D0%BE%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B4%D0%BB%D1%8F%20%D1%81%D0%B2%D1%8F%D0%B7%D0%B8%20%D1%81%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC%20%D0%BC%D0%B5%D0%BD%D0%B5%D0%B4%D0%B6%D0%B5%D1%80%D0%BE%D0%BC%20%23435923%20%D0%A1%D0%BF%D0%B0%D1%81%D0%B8%D0%B1%D0%BE%21" class="icon">
                    <img  src="assets/footer/Vector03.svg" class="icon__img"/>
                </a>
              </div>
              <a class="text-prava">Все права защищены &copy; 2024 - 2025 Компания ООО "ТРИП-АДВАНС"</a>
           </div>
        </div>
        <div class="wrapper-content__riht">
           <iframe class="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A6786e375f6dbfbf3b73ced6373eab0fd80d0c592173c49ca10d61fd33ef5a783&amp;source=constructor"  frameborder="0"></iframe>
        </div>

    </div>

</div>
