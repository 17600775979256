
<div class="wrapper">

    <div class="title">
        <h1 class="title__text1">КОМПЛЕКС РЕШЕНИЙ</h1>
        <div class="title__block2">
            <p class="title__text2">Широкий спектр услуг и возможностей для гостиничного бизнеса, в программе 1С:ОТЕЛЬ/2024</p>
        </div>
    </div>

    <div class="button-wrapp " >
        <app-button-slider class="desktop" (onClick)="($event == 1) ? this.productNext() : this.productPrev()"> </app-button-slider> 
    </div>
   

    
</div>

<div class="wrapper-products">

    <div class="new"> 
        <p  class="new-block__text1">Новое</p>
        <div class="new-block">
            <app-element-minimize color="white"  sizeTitle="16" [item]="this.site.elements.minimize[3]" padding="0px"></app-element-minimize>
        </div>

        <!--
        <div class="new-separator desktop"></div>
-->
        <div class="new-block">
            <app-element-minimize color="white" sizeTitle=""  [item]="this.site.elements.minimize[4]" padding="0px"></app-element-minimize>
        </div>

    </div> 

    <div class="products">
        <app-slider [type]="this.animationType" [countView]="this.getCountProductView()" [duration]="500" (onChange)="this.changeSlide($event)">
            
            <app-slide *ngFor="let item of this.site.elements.dicisions">
                

                
                
                
                <app-element-dicision ngSkipHydration [item]="item" ></app-element-dicision>
                
                
            </app-slide>
                       
        </app-slider> 
        
        <div class="block-progress mobile"> 
            <div class="counter">
               <div class="counter_item" [ngClass]="getCounterColor(0)" (click)="setBaner(getPaginatedIndex(0))"></div>
               <div class="counter_item" [ngClass]="getCounterColor(1)" (click)="setBaner(getPaginatedIndex(1))"></div>
               <div class="counter_item" [ngClass]="getCounterColor(2)" (click)="setBaner(getPaginatedIndex(2))"></div>
            </div>
        </div>
         
    </div>
  
  
</div>

<!--для пагинации ставим в тег слайдера (onChange)="this.changeSlide($event)" связывая блоки пагинации и слайда -->