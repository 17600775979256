
<!--

-->

<div class="dop-interval__mobile">
    <app-interval-big></app-interval-big> 
 </div>
<app-main-baner></app-main-baner>

<div class="wrapper">
   
   

    <app-interval-mini></app-interval-mini>
    <app-main-decisions ></app-main-decisions>
   

  <!--<div style="margin-top:64px">
        <app-main-content></app-main-content>
    </div>-->

    <div class="dop-interval__deckstop"> 
        <app-interval-big></app-interval-big>
     </div>
       <!-- <app-main-present></app-main-present>-->
        <app-main-present-two></app-main-present-two>
    
            
 
        <app-interval-big></app-interval-big>
        <app-main-products></app-main-products>
 

        <app-interval-big></app-interval-big>
        <div class="section-width-100 dop-marg">
        <app-banner-bottom-animation></app-banner-bottom-animation>
        </div>
  
        <app-interval-big></app-interval-big>
        <div class="section-width-75"  >
          <app-discriptions  
            [link]="'Ссылка'"
            [title]="'Компания TripAdvance'"
            [subtitle]="'За каждым достижением стоит команда'"
        >
            <div class="text__block">
            <h3 style="width: 100%;" class="text">Наша компания занимается разработкой и поддержкой программного обеспечения на базе платформы 1С:Отель. Мы предоставляем полный спектр услуг от разработки
                и <a class="text__block-link" href="/services/integration/hotel" style="color:#ED3E3E;">внедрения</a> до сопровождения и поддержки. Имеем большой опыт в области автоматизации бизнес-процессов и готовы предложить нашим клиентам индивидуальные
                решения. Наши специалисты имеют высокую квалификацию и опыт работы в сфере 1С, что позволяет нам быстро и качественно выполнять задачи любой сложности</h3>
             </div> 
             <div style="margin-top: 50px;"></div>
            <a href="o-nas">
                <app-button ngSkipHydration>О КОМПАНИИ</app-button> 
            </a>
          </app-discriptions>
        </div>

        <app-interval-big></app-interval-big>
        <div   class="section-width-100">
            <app-banner-content></app-banner-content>
        
            <app-interval-mini></app-interval-mini>
            <div style=" width: 95%; height: 0.5px; background: #c9c9c9b3; margin: auto;"></div>
            <div class="partner-slider">
               <app-element-partners></app-element-partners>
            </div>
        </div>     
        
      
        <app-interval-big></app-interval-big>
        <div class="section-width-100">
            <app-paralel-scroll ngSkipHydration></app-paralel-scroll>
        </div> 


        
        
        <div class="dop-interval__deckstop">  
            <app-interval-big></app-interval-big>
            
         </div>
         <div class="margin-main">

            <div class="section-width-100 mobile">
                <app-main-mobile-cards></app-main-mobile-cards>
            </div>
            <div class="dop-interval__mobile">  
                <app-interval-big></app-interval-big>
                
             </div>
           <app-footer></app-footer>  
        </div> 

         <div  *ngIf="page.formContent">
            <app-modal-forms [item]="page.formContent"></app-modal-forms>
          </div>

        <div *ngIf="page.formContent">
            <app-popup-form  [item]="page.formContent2"></app-popup-form> 
        </div>
       
<!--
    <div style="margin: 64px 24px 0px 24px">
        <app-main-chapters></app-main-chapters>
    </div>

    <div style="margin: 64px 24px 0px 24px">
        <app-main-integrations></app-main-integrations>
    </div>     

    <div style="margin: 64px 24px 0px 24px">
        <app-main-partners></app-main-partners>
    </div>   

    <div style="margin: 64px 24px 0px 24px">
        <app-main-company></app-main-company>
    </div>   

    <div style="margin: 64px 24px 0px 24px">
        <app-main-news></app-main-news>
    </div> 
    
-->
   <!-- <app-modal-window></app-modal-window>-->
</div>
