import { Component } from '@angular/core';

@Component({
  selector: 'app-inputs-forms',
  templateUrl: './inputs-forms.component.html',
  styleUrls: ['./inputs-forms.component.scss']
})
export class InputsFormsComponent {

}
