import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { VisibilityService } from '../../servises/Visibility.service'; // сервис обмена между компонентами авто закрытие формы
import { SiteService } from '../../site.service';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-modal-forms',
  templateUrl: './modal-forms.component.html',
  styleUrls: ['./modal-forms.component.scss']
})
export class ModalFormsComponent implements OnInit {

  @Input() item: any = {};
  bgOpen = '';
  showUpper = true; // Изначально показываем верхнюю часть формы
  isVisible = false;
  subscription!: Subscription;
  products:any = undefined;
  
  constructor(
    private visibilityService: VisibilityService,
    public site: SiteService,
    private openFormService: openFormService
  ) {
    this.subscription = this.visibilityService.hideElement$.subscribe((item:any) => {

      setTimeout(() => {
        this.isVisible = false;
      }, 7000); // Задержка в 7 секунд (7000 мс)
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Отписываемся от подписки при уничтожении компонента
    }
  }

  ngOnInit(): void {
    this.openFormService.backgroundChanged.subscribe((item:any) => {
      this.products = item;
      this.isVisible = true; // Открываем форму
      this.bgOpen = 'bg-open'; // Меняем класс
      this.showUpper = true; // Устанавливаем showUpper в true при каждом открытии формы
    });
  }

  toggleComponents() {
    this.showUpper = !this.showUpper; // Переключаем между верхним и нижним компонентами
  }

  onRemoveUpper() {
    this.showUpper = false; // Переходим к нижней части формы
  }

  close() {
    this.isVisible = false; // Закрываем всю форму
    this.bgOpen = ''; // Убираем класс
  }
}