import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';
import { openFormService } from '../../servises/openForm.service';
 
@Component({
  selector: 'app-services-integration-bitrix24',
  templateUrl: './services-integration-bitrix24.component.html',
  styleUrls: ['./services-integration-bitrix24.component.scss']
})
export class ServicesIntegrationBitrix24Component {
  page:any = {};
  bgOpen = ''; 
  constructor(public site:SiteService, private route: ActivatedRoute, private openFormService: openFormService){

  } 

  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage(); 

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
    });
  }

  ngAfterContentInit(){
  }
}
