<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average></app-interval-average>
</div>


<div  class="section-width-100">

    <div class="section-width-90">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <app-interval-average></app-interval-average>

    <div class="section-width-90">
      <app-element-product-text
       [item]="this.page.text1">
      </app-element-product-text> 
   </div> 
 
   <app-interval-average></app-interval-average>
   <div class="section-width-75">
      <app-element-tags [item]='[
           { text:"Модуль бронирования", href:"developments/booking-module",},
           { text:"Обучение 1С:Отель с нуля", href:"training-1c-hotel"},
           { text:"Лицензии", href:"programming/licenzii"},
           { text:"1С + кассы онлайн", href:"devices/cash-registers"},
           { text:"Услуги программиста по 1С: Отель", href:"escort-1c-hotel"},
           
         ]'>
         </app-element-tags>
         <!--{ text:"Модуль бронирования"},
           { text:"Обучение 1С:Отель с нуля"}, 
           { text:"1С:Отель + Битрикс24"},
           { text:"1С: Отель отчеты"},
           { text:"Лицензии"},
           { text:"Программа 1С: Отель"},
           { text:"1С + кассы онлайн"},
           { text:"Разработка печатных форм"},
           { text:"Услуги программиста по 1С: Отель"},-->
     </div>
 
   <app-interval-average></app-interval-average>
    <div class="section-width-75">
       <app-accordion-all-pages ngSkipHydration *ngIf="this.page.accordion!=undefined"
          [accordions]="this.page.accordion">
       </app-accordion-all-pages>
    </div>

    <app-interval-big></app-interval-big>
    <div class="packet-decktop">
      <app-element-packages [item]="this.page.packages">
      </app-element-packages>
    </div>
     
    <div class="section-width-100 packet-mobile">
       <app-packets-mobile [item]="this.page.packages"></app-packets-mobile>
    </div> 
   
     <app-interval-big></app-interval-big> 
     <div class="section-width-100 section-color">
        <div class="margins-section-accordion2__element">
           <app-accordion-all-pages-two ngSkipHydration *ngIf="this.page.accordion!=undefined"
             [accordionsT]="this.page.accordion2"></app-accordion-all-pages-two>
        </div>
    </div>
   
    <app-interval-mini></app-interval-mini>
    <div class="section-width-100">
       <app-footer></app-footer> 
     </div> 
 </div>
 
 
 <div  *ngIf="page.formContent">
   <app-modal-forms [item]="page.formContent"></app-modal-forms>
 </div>