<div class="wrapper">
   <div class="wrapper-products">
        <div class="products"> 
            <div class="product">
                <div class="product-top">
                    
                    <div class="product-header">
                        <!--<div class="product-header__title">
                            <h3 class="product-header__title_text1">{{this.item.title}}</h3>
                            <h3 class="product-header__title_text2">{{this.item.description}}</h3>
                        </div>   -->
                        <img class="product-header__title_icon" [attr.src]="this.item.image">
                    </div>
                </div>
                <div class="product-bottom"> 
                    <div class="product-navigation"> 
                        <h3 class="product-header__title_text1">{{this.item.title}}</h3>
                    </div>
                    <p class="product-description">{{this.item.text}}</p>
                    <div class="wraper-button">
                        <a [attr.href]="this.item.href"> 
                          <app-button ngSkipHydration [class.noactive]="item.titleLink==''">{{this.item.titleLink}}</app-button>
                        </a>
                    </div>
                </div>
              
            
            </div>
        </div>

    </div>
</div>
