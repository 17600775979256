<section class="wrapp">
    <div class="contenier-card">
       <div class="cards cards-hover__one" >
          <div class="cards__cont-title">
             <h3 class="cards__title color-txt__dark">РАССШИРЕНИЯ</h3>
             <h4 class="cards__subtitle color-txt__dark">РАЗРАБОТКА</h4>
             <h4 class="cards__subtitle color-txt__dark">ПРОГРАММИРОВАНИЕ</h4>
          </div>
          <img class="cards__img cards__img-size"  src="../../../assets/main/B1.png"/>

          <div class="content-feetbeck-one">
           <div class="cards__cont-title-one ">
               <h3 class="cards__title color-txt__lite-one title-dark">РАССШИРЕНИЯ</h3>
               <h4 class="cards__subtitle color-txt__lite-one title-dark">РАЗРАБОТКА</h4>
               <h4 class="cards__subtitle color-txt__lite-one title-dark" >ПРОГРАММИРОВАНИЕ</h4>
            </div>
           <p class="content-feetbeck__text-one color-txt__lite-one">Индивидуальная разработка нетиповых решений под нужды клиента, от печатных форм до собственных конфигураций. (Парковка, эл. замки, бронировние в Битрикс 24, персонализация карт и другое)</p>
           <a href="services/integration/bitrix24">
             <app-button ngSkipHydration class="feetback-button">ПЕРЕЙТИ</app-button>
           </a>
        </div>

       </div>
       <div class="cards cards-hover bg-color-dsrk">
           <div class="cards__cont-title">
               <h3 class="cards__title color-txt__lite title-white">ВНЕДРЕНИЕ</h3>
               <h4 class="cards__subtitle color-txt__lite title-white">ПРОГРАММА 1С </h4>
               <h4 class="cards__subtitle color-txt__lite title-white" >ИНТЕГРАЦИИ</h4>
            </div>
         
            <div class="content-front-feetbeck  bg-color-dsrk">
              
                <img class="content-front__img" src="../../../assets/main/B2V2.png"/>
             
                <div class="content-feetbeck">
                   <p class="content-feetbeck__text title-white">Двухсторонние интеграции между программой 1С: Отель и модулем бронирования. Интеграция с популярными менеджерами каналов (Яндекс Путешествия и другими), Формирование брони в Битрикс 24</p>
                   <a href="services/integration/hotel">
                      <app-button ngSkipHydration class="feetback-button">ПЕРЕЙТИ</app-button>
                   </a>
                </div>
            </div>
       </div>
       <div class="cards cards-hover">
           <div class="cards__cont-title">
               <h3 class="cards__title color-txt__lite ">ОБСЛУЖИВАНИЕ</h3>
               <h4 class="cards__subtitle color-txt__lite">ОБОРУДОВАНИЕ</h4>
               <h4 class="cards__subtitle color-txt__lite">ПОДКЛЮЧЕНИЕ</h4>
            </div>
            <div class="content-front-feetbeck">
          
                   <img class="content-front__img overflow-img" src="../../../assets/main/B3V2.png"/>
            
               <div class="content-feetbeck">
                  <p class="content-feetbeck__text">Продажа и настройка оборудования: кассы - онлайн, считыватели штрих кодов, сканеры. Регистрация в ФНС, ОФД, ККМ. Отладка сетевого режима, web сервиса</p>
                  <a href="devices/cash-registers">
                    <app-button ngSkipHydration class="feetback-button">ПЕРЕЙТИ</app-button>
                  </a>
               </div>
           </div>
       </div>
       <div class="cards cards-hover bg-color-dsrk">
           <div class="cards__cont-title">
               <h3 class="cards__title color-txt__lite title-white">ОБЩЕПИТ</h3>
               <h4 class="cards__subtitle color-txt__lite title-white">АВТОМАТИЗАЦИЯ</h4>
               <h4 class="cards__subtitle color-txt__lite title-white">РЕСТОРАНА</h4>
            </div>
            <div class="content-front-feetbeck">
               <div></div>
               <img class="content-front__img" src=".../../../assets/main/BLOCK 4 V3.png"/>
               <div class="content-feetbeck">
                
                  <p class="content-feetbeck__text title-white">Создание и настройка Back-Ofice / Front - Ofice. Подключение ЕГАИС. Регистрация в честном знаке. Подключение к ЭДО. Настройка УТМ. Полная настройка торгового оборудование, обучение сотрудников</p>
                  <a href="services/integration/traktir">
                    <app-button ngSkipHydration class="feetback-button" >ПЕРЕЙТИ</app-button>
                  </a>
               </div>
           </div>
         
       </div>
       <div class="cards mobile-cards">
        <img class="cards-logo" src="../../../assets/main/LOGO BLOCK.png"/>
    </div>
    </div>
</section>
