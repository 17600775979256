import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-training-one-c-hotel',
  templateUrl: './training-one-c-hotel.component.html',
  styleUrls: ['./training-one-c-hotel.component.scss']
})
export class TrainingOneCHotelComponent {
 
  currentPacket: any = [];
  activePacket:any = 0; 
  page:any = {};
  bgOpen = ''; 

  constructor(public site:SiteService, private route: ActivatedRoute, private openFormService: openFormService){

  }

  ngOnInit(): void {   
    this.currentPacket = this.site.packages.training;
    this.page = this.site.GetCurrentPage();

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
    });
  }

  ngAfterContentInit(){
  }
}
