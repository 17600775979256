<div class="wrapp">
    <div class="left">
        <img class="left__img" [src]="this.item.image"/> 
    </div>
    <div class="riht">
       <p class="riht__title">{{this.item.name}}</p>
       <div>
         <p class="riht__code">{{this.item.id}}</p>
         <p class="riht__prise">{{this.item.price}} ₽</p>
         <div class="wrapp-button">
            <app-button ngSkipHydration (onClick)="this.openProduct(this.item)">ПОДРОБНЕЕ</app-button>
         </div>
       </div>
    </div> 
</div>
 