<div class="wrapper">
    <div class="conteiner-top">
       <div class="conteiner-top__text-content"> 
        <div>
          <div class="conteiner-top-title-content">
             <h2 class="conteiner-top-title-content__title">{{this.item.texts.title}}<br>{{this.item.texts.title2}}</h2>
             <img class="conteiner-top-title-content__img" [attr.src]="this.item.texts.iconTitle"/>
          </div>
          <h3 class="conteiner-top__text-content-subtitle">{{this.item.texts.subtitle}}</h3>
          <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" [attr.src]="this.item.texts.iconSubtitle"/>{{this.item.texts.list}}</p>
          <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" [attr.src]="this.item.texts.iconSubtitle"/>{{this.item.texts.list2}}</p>
          <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" [attr.src]="this.item.texts.iconSubtitle"/>{{this.item.texts.list3}}</p>
          <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" [attr.src]="this.item.texts.iconSubtitle"/>{{this.item.texts.list4}}</p>
        </div>
          <h4 class="conteiner-top__text-content-price">{{this.item.texts.price}}&#8381;
            <h5 class="conteiner-top__text-content-price-sale">{{this.item.texts.priceDiscount}}
                <p class="conteiner-top__text-content-price-new">{{this.item.texts.priceNew}}</p>
            </h5></h4>
       </div>
       <img class="conteiner-top__img" [attr.src]="this.item.image"/>
    </div>

    <div class="conteiner-bottom">
      <app-button ngSkipHydration class="conteiner-bottom-button">ОФОРМИТЬ</app-button>
      <p class="conteiner-bottom__text">{{this.item.texts.footerText}}<br>{{this.item.texts.footerText2}}</p>
    </div>
</div>

