<div class="wrapper">
    <div class="wrapper-block">
        <div class="wrapper-text">
            <div class="input__text">
                <input type="text" #inputText
                    [(value)]="this.value"
                     type="text" 
                     class="input" 
                     [autocomplete]="autocomplete"
                     [name]="name"
                     [placeholder]="this.placeholder"
                     (paste)="onPaste($event)"
                     (keypress)="this.onKeyPress($event)" 
                     (input)="$event.stopPropagation(); onInput.emit($event);"
                     [style.color]="(this.validPhone==true)?'#b2b2b2':'#ED3E3E'"/>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="required && this.validPhone==false" class="requred">*</div> -->
</div>
