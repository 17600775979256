<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average></app-interval-average>
</div>

<div class="section-width-100">

    <div class="section-width-90">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <app-interval-average></app-interval-average>

    <div class="section-width-90">
      <app-element-product-text
       [item]="this.page.text1"> 
      </app-element-product-text> 
   </div>



   <div class="dop-interval__deckstop">
      <app-interval-average></app-interval-average>
   </div>
   <div class="section-width-90">
      <app-discription-row
        [title2]="'Полный адрес'"
        [subtitle]="'353435 Краснодарский край г.о. город-курорт Анапа г. Анапа х. Усатова Балка ул. Красная д. 1/9 кв. 3'"
        [subtitle2]="'Пн-Пт с 9:00 - 18:00'"
        [subtitle3]="'Сб - Вс выходные дни'"
        [title3]="'Единый бесплатный телефон'"
        [subtitle4]="'8 (861) 213-23-13'"
        [title4]="'По вопросам сотрудничества'"
        [subtitle5]="'8 (993) 631-64-93'"
        [title5]="'По вопросам трудоустройства'"
        [subtitle6]="'8 (996) 405-07-36'"
        [title6]="'Официальная почта'"
        [subtitle7]="'acc@tripadvance.ru'"
        [title7]="'Почта'"
        [subtitle8]="'product@tripadvance.ru'"
        [title8]="'Почта'"
        [subtitle9]="'support@tripadvance.ru'"
      >
      </app-discription-row> 
   </div>
   <div class="dop-interval__deckstop">
      <app-interval-average></app-interval-average>
   </div>
  


   <div class="section-width-90">
      <app-element-card-marketing  [item]="this.page.marketingCards"></app-element-card-marketing>
   </div>
    
   <app-interval-average></app-interval-average>
   <div class="section-width-100">
      <app-footer></app-footer> 
    </div>
 </div>
    
 <div  *ngIf="page.formContent">
   <app-modal-forms [item]="page.formContent"></app-modal-forms>
 </div>

 <div *ngIf="page.formContent">
   <app-popup-form  [item]="page.formContent2"></app-popup-form> 
</div>

<div *ngIf="page.formContent">
   <app-modal-form2  [item]="page.formContent3"></app-modal-form2> 
</div>