<div class="wrapper">
    <div class="wrapper__text-block">
        <h3 class="wrapper__text-block-title" *ngFor="let title of this.item.title">{{title}}</h3>
        <div class="wrapper__text-block-subtitle-contenier">
            <p class="wrapper__text-block-subtitle">Синхронизация с картами гостя</p>
            <p class="wrapper__text-block-subtitle">Онлайн контроль посещаемости</p>
            <p class="wrapper__text-block-subtitle">Интеграция с программой 1С: Отель</p>
        </div>
        <app-button ngSkipHydration (click)="this.onClickButton()">Подробнее</app-button>
        <p class="wrapper__text-block-text">Базовые и индивидуальные решения!</p>
    </div>


    <div class="wrapper__button-block"> 
        
        <div class="wrapper__cards-animation">

            <div class="wrapper__cards-animation-backraund">
                <h3 class="wrapper__cards-animation-title">Электронные замки </h3>
                <img class="wrapper__cards-animation-icon" src="assets/main/door.png"/>
                <div class="wrapper__cards-animation-img backgaund-img"></div>
                <div class="wrapper__cards-animation-color"></div>
                <!--<div id="wrapper__card-animation_hidden" class="wrapper__cards-animation-arrow">
                    <a class="wrapper__link" href="#">  
                        <svg class="button-with" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 50 80" xml:space="preserve">
                          <polyline fill="none" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" points="
                           0.375,0.375 45.63,38.087 0.375,75.8 "/>
                        </svg>
                    </a>
                </div>-->
             </div>


             

             <div id="wrapper__card-animation_hidden" class="wrapper__cards-animation-backraund backraund-mobile ">
                <h3 class="wrapper__cards-animation-title">Персонализация карт гостей</h3>
                <img class="wrapper__cards-animation-icon" src="assets/main/man.png"/>
                <div class="wrapper__cards-animation-img backgaund-img2"></div>
                <div class="wrapper__cards-animation-color"></div>
                <!--<div id="wrapper__card-animation_hidden" class="wrapper__cards-animation-arrow">
                    <a class="wrapper__link" href="#">  
                        <svg class="button-with" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 50 80" xml:space="preserve">
                          <polyline fill="none" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" points="
                           0.375,0.375 45.63,38.087 0.375,75.8 "/>
                        </svg>
                    </a>
                </div>-->
             </div>
    
        </div>    
    </div>
</div>
