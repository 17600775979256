<div class="wrapper">
    <div class="title">
        <h1 class="title__text">ПРОГРАММНЫЕ ПРОДУКТЫ</h1>
        <a href="services/programming/licenzii"><app-red-arrow></app-red-arrow></a>
    </div>

    <div class="line"></div> 


    
    <app-product-slider  [products]="'productslicesii'"></app-product-slider>

</div>

<div class="wrapper">
    <div class="title">
        <h1 class="title__text">ОБОРУДОВАНИЕ</h1>
        <a href="devices/cash-registers"><app-red-arrow></app-red-arrow></a>
    </div>
 
    <div class="line"></div>

    <app-product-slider [products]="'productsKass'"></app-product-slider>
</div>
