
<div  class="wrapper">

    <app-slider  [type]="this.animationType" [duration]="this.animationDuration" [autoPlayDelay]="10000" (onChange)="this.changeSlide($event)"> <!--(onAnimate)="this.sliderAnimate($event)-->
        <app-slide>
            <div class="banner banner__bg">
               <div class="left">
                   <div class="top">
                      <img class="top-logo" src="assets/main/Logo TA.png" alt="logo"/>
                   </div>
                   <div class="bottom">
                       <div class="bottom-left">
                          <img class="bottom-left__line" src="assets/main/Line 1.png" alt="logo"/>
                       </div>
                       <div class="bottom-riht">
                          <h2 class="bottom-riht__title">СИСТЕМА БРОНИРОВАНИЯ ДЛЯ ОТЕЛЕЙ БЕЗ КОМИССИИ</h2>
                          <h3 class="bottom-riht__subtitle">Модуль online бронирования</h3>
                       </div>
                   </div>
               </div>
                <div class="riht">
                    <img class="riht-img" src="assets/main/Blok 1.png" alt="правая картинка"/>
                </div>
            </div> 
        </app-slide>
        <app-slide>
            <div class="banner banner__bg1">
                <div class="left">
                    <div class="top">
                       <img class="top-logo" src="assets/main/Logo 1C.png" alt="logo"/>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                           <img class="bottom-left__line" src="assets/main/Line 2.png" alt="logo"/>
                        </div>
                        <div class="bottom-riht">
                           <h2 class="bottom-riht__title">СОВРЕМЕННОЕ УПРАВЛЕНИЕ ОТЕЛЕМ В 2025</h2>
                           <h3 class="bottom-riht__subtitle">Внедрение / Сопровождение программы 1С: Отель</h3>
                        </div>
                    </div>
                </div>
                 <div class="riht">
                     <img class="riht-img" src="assets/main/Blok 2.png" alt="правая картинка"/>
                 </div>
               
            </div>
        </app-slide> 
        <app-slide>
            <div class="banner banner__bg2">
              
                <div class="left">
                    <div class="top">
                       <img class="top-logo" src="assets/main/Logo B.png" alt="logo"/>
                    </div>
                    <div class="bottom">
                        <div class="bottom-left">
                           <img class="bottom-left__line" src="assets/main/Line 3.png" alt="logo"/>
                        </div>
                        <div class="bottom-riht">
                           <h2 class="bottom-riht__title">АВТОМАТИЗАЦИЯ СЛОЖНЫХ БИЗНЕС ПРОЦЕССОВ ОТЕЛЯ</h2>
                           <h3 class="bottom-riht__subtitle">Формирования бронирований через сделки</h3>
                        </div>
                    </div>
                </div>
                 <div class="riht">
                     <img class="riht-img" src="assets/main/Blok 3.png" alt="правая картинка"/>
                 </div>
                        
            </div>
        </app-slide>  
        
        
    </app-slider>

   
    <div class="block-progress"> 
        <div class="counter">
            <div class="counter_item" [ngClass]="getCounterColor(0)" (click)="setBaner(getPaginatedIndex(0))"></div>
            <div class="counter_item" [ngClass]="getCounterColor(1)" (click)="setBaner(getPaginatedIndex(1))"></div>
            <div class="counter_item" [ngClass]="getCounterColor(2)" (click)="setBaner(getPaginatedIndex(2))"></div>
          </div>
    </div>
 
 
  
</div>