import { Component } from '@angular/core';

@Component({
  selector: 'app-interval-mini',
  templateUrl: './interval-mini.component.html',
  styleUrls: ['./interval-mini.component.scss']
})
export class IntervalMiniComponent {

}
