import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent }   from './app.component';
import { MainComponent }   from './pages/main/main.component';
import { ProductsComponent }   from './pages/products/products.component';
import { CompanyComponent }   from './pages/company/company.component';
import { SearchComponent }   from './pages/search/search.component';
import { ServicesComponent }   from './pages/services/services.component';
import { DevicesComponent }   from './pages/devices/devices.component';
import { ProgrammsComponent }   from './pages/programms/programms.component';
import { DevelopmentsComponent }   from './pages/developments/developments.component';
import { IntegrationsComponent }   from './pages/integrations/integrations.component';
import { ContactsComponent }   from './pages/contacts/contacts.component';





import { BookingModuleComponent }   from './pages/booking-module/booking-module.component';
import { ServicesIntegrationHotelComponent } from './pages/services-integration-hotel/services-integration-hotel.component';
import { ServicesIntegrationBitrix24Component } from './pages/services-integration-bitrix24/services-integration-bitrix24.component';
import { ServicesIntegrationBpComponent } from './pages/services-integration-bp/services-integration-bp.component';
import { ServicesIntegrationUtComponent } from './pages/services-integration-ut/services-integration-ut.component';
import { ServicesIntegrationRoznicaComponent } from './pages/services-integration-roznica/services-integration-roznica.component';
import { ServicesIntegrationTraktirComponent } from './pages/services-integration-traktir/services-integration-traktir.component';
import { ServicesIntegrationBookingModuleComponent } from './pages/services-integration-booking-module/services-integration-booking-module.component';

import { ServicesEscortHotelComponent } from './pages/services-escort-hotel/services-escort-hotel.component';
import { ServicesEscortBpComponent } from './pages/services-escort-bp/services-escort-bp.component';
import { ServicesEscortUtComponent } from './pages/services-escort-ut/services-escort-ut.component';

import { ServicesProgrammingExtensionsComponent } from './pages/services-programming-extensions/services-programming-extensions.component';
import { ServicesProgrammingPrintedFormsComponent } from './pages/services-programming-printed-forms/services-programming-printed-forms.component';
import { ServicesProgrammingReportsComponent } from './pages/services-programming-reports/services-programming-reports.component';
import { ServicesProgrammingProcessingComponent } from './pages/services-programming-processing/services-programming-processing.component';

import { ServicesFreshComponent } from './pages/services-fresh/services-fresh.component';
import { ServicesGrmComponent } from './pages/services-grm/services-grm.component';


import { TrainingClassComponent } from './pages/training-class/training-class.component';
import { TrainingOnlineComponent } from './pages/training-online/training-online.component';
import { TrainingIndividualComponent } from './pages/training-individual/training-individual.component';

import { DevicesCashRegistersComponent } from './pages/devices-cash-registers/devices-cash-registers.component';
import { DevicesHotelComponent } from './pages/devices-hotel/devices-hotel.component';


import { PartnersComponent } from './pages/partners/partners.component';

import { DevelopmentsBookingModuleComponent } from './pages/developments-booking-module/developments-booking-module.component';
import { DevelopmentsUkdComponent } from './pages/developments-ukd/developments-ukd.component';

import { TestComponent } from './test/test.component';
import { TestBlocksComponent } from './pages/test-blocks/test-blocks.component';
import { FiscalStorageComponent } from './pages/fiscal-storage/fiscal-storage.component';
import { ServicesProgrammingLicenziiComponent } from './pages/services-programming-licenzii/services-programming-licenzii.component';
import { ONasComponent } from './pages/o-nas/o-nas.component';
import { TehStackComponent } from './pages/teh-stack/teh-stack.component';
import { PoliticsConfendialityComponent } from './pages/politics-confendiality/politics-confendiality.component';
import { TrainingOneCHotelComponent } from './pages/training-one-c-hotel/training-one-c-hotel.component';
import { EscortOneCHotelComponent } from './pages/escort-one-c-hotel/escort-one-c-hotel.component';
import { EquipmentRegistrationFnsComponent } from './pages/equipment-registration-fns/equipment-registration-fns.component';
import { EquipmentConnectionOfdComponent } from './pages/equipment-connection-ofd/equipment-connection-ofd.component';
import { EquipmentCustomizationKkmComponent } from './pages/equipment-customization-kkm/equipment-customization-kkm.component';
import { EquipmentConnectionOneCComponent } from './pages/equipment-connection-one-c/equipment-connection-one-c.component';
import { ProgramsConnectionEgaisComponent } from './pages/programs-connection-egais/programs-connection-egais.component';
import { ProgramsConnectionChestnuiZnakComponent } from './pages/programs-connection-chestnui-znak/programs-connection-chestnui-znak.component';
import { ProgramsConnectionEdoComponent } from './pages/programs-connection-edo/programs-connection-edo.component';
import { ProgramsConnectionUtmComponent } from './pages/programs-connection-utm/programs-connection-utm.component';
import { DevicesBarcodeScannerComponent } from './pages/devices-barcode-scanner/devices-barcode-scanner.component';

const routes: Routes = [
  { path: '#', component: MainComponent},
  
 
  { path: '', component: MainComponent},
  { path: 'products', component: ProductsComponent},
  { path: 'company', component: CompanyComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'test', component: TestBlocksComponent},
  



  // Услуги - Внедрение
  { path: 'services/integration/hotel', component: ServicesIntegrationHotelComponent},
  { path: 'services/integration/bp', component: ServicesIntegrationBpComponent},
  { path: 'services/integration/ut', component: ServicesIntegrationUtComponent},
  { path: 'services/integration/roznica', component: ServicesIntegrationRoznicaComponent},
  { path: 'services/integration/traktir', component: ServicesIntegrationTraktirComponent},
  { path: 'services/integration/bitrix24', component: ServicesIntegrationBitrix24Component},
  { path: 'services/integration/booking-module', component: ServicesIntegrationBookingModuleComponent},
  
  // Услуги - Сопровождение
  { path: 'services/escort/hotel', component: ServicesEscortHotelComponent},
  { path: 'services/escort/bp', component: ServicesEscortBpComponent},
  { path: 'services/escort/ut', component: ServicesEscortUtComponent},

  // Услуги - Программирование
  { path: 'services/programming/printed-forms', component: ServicesProgrammingPrintedFormsComponent},
  { path: 'services/programming/reports', component: ServicesProgrammingReportsComponent},
  { path: 'services/programming/extensions', component: ServicesProgrammingExtensionsComponent},
  { path: 'services/programming/processing', component: ServicesProgrammingProcessingComponent},
  { path: 'services/programming/licenzii', component: ServicesProgrammingLicenziiComponent},
  
  // Обучение
  { path: 'services/training/class', component: TrainingClassComponent},
  { path: 'services/training/online', component: TrainingOnlineComponent},
  { path: 'services/training/individual', component: TrainingIndividualComponent},

  { path: 'services/fresh', component: ServicesFreshComponent},
  { path: 'services/grm', component: ServicesGrmComponent},
  

  { path: 'developments/booking-module', component: DevelopmentsBookingModuleComponent},
  { path: 'developments/ukd', component: DevelopmentsUkdComponent},
//онлайн кассы
  { path: 'devices/cash-registers', component: DevicesCashRegistersComponent},
  { path: 'devices/cash-registers/:id', component: DevicesCashRegistersComponent},

  //сканеры штрихкода
  { path: 'devices/barcode-scanner', component: DevicesBarcodeScannerComponent},
  { path: 'devices/barcode-scanner/:id', component: DevicesBarcodeScannerComponent},
//?
  { path: 'devices/hotel', component: DevicesHotelComponent},
  { path: 'devices/hotel/:id', component: DevicesHotelComponent},

//фискальные накопители 
{ path: 'devices/fiscal-storage', component: FiscalStorageComponent},
{ path: 'devices/fiscal-storage/:id', component: FiscalStorageComponent},

//о нас
{ path: 'o-nas', component: ONasComponent},
{ path: 'o-nas/:id', component: ONasComponent},

//партнерам
{ path: 'partners', component: PartnersComponent},
{ path: 'partners/:id', component: PartnersComponent},

//тех стек
{ path: 'teh-stack', component: TehStackComponent},
{ path: 'teh-stack/:id', component: TehStackComponent},

//политика конф
{ path: 'politics-confendiality', component: PoliticsConfendialityComponent},
{ path: 'politics-confendiality/:id', component: PoliticsConfendialityComponent},

//обучение 1с отель
{ path: 'training-1c-hotel', component: TrainingOneCHotelComponent},
{ path: 'training-1c-hotel/:id', component: TrainingOneCHotelComponent},

//сопровождение 1с отель
{ path: 'services/escort-1c-hotel', component: EscortOneCHotelComponent},
{ path: 'services/escort-1c-hotel/:id', component: EscortOneCHotelComponent},

//оборудование-регистрация ФНС
{ path: 'equipment/online-sales-register/registration-fns', component: EquipmentRegistrationFnsComponent},
{ path: 'equipment/online-sales-register/registration-fns/:id', component: EquipmentRegistrationFnsComponent},

//Подключение кассы- онлайн к ОФД
{ path: 'equipment/online-sales-register/connection-ofd', component: EquipmentConnectionOfdComponent},
{ path: 'equipment/online-sales-register/connection-ofd/:id', component: EquipmentConnectionOfdComponent},

//Настройка кассы- онлайн ККМ 
{ path: 'equipment/online-sales-register/customization-kkm', component: EquipmentCustomizationKkmComponent},
{ path: 'equipment/online-sales-register/customization-kkm/:id', component: EquipmentCustomizationKkmComponent},

//Подключение кассы- онлайн к программе 1С
{ path: 'equipment/online-sales-register/connection-1C', component: EquipmentConnectionOneCComponent},
{ path: 'equipment/online-sales-register/connection-1C/:id', component: EquipmentConnectionOneCComponent},

//Подключение к ЕГАИС 
{ path: 'equipment/programs/connection-egais', component: ProgramsConnectionEgaisComponent},
{ path: 'equipment/programs/connection-egais/:id', component: ProgramsConnectionEgaisComponent},

//Регистрация - Честный знак 
{ path: 'equipment/programs/connection-chestnui-znak', component: ProgramsConnectionChestnuiZnakComponent},
{ path: 'equipment/programs/connection-chestnui-znak/:id', component: ProgramsConnectionChestnuiZnakComponent},

//Подключение к ЭДО 
{ path: 'equipment/programs/connection-edo', component: ProgramsConnectionEdoComponent},
{ path: 'equipment/programs/connection-edo/:id', component: ProgramsConnectionEdoComponent},

//Настройка УТМ
{ path: 'equipment/programs/connection-utm', component: ProgramsConnectionUtmComponent},
{ path: 'equipment/programs/connection-utm/:id', component: ProgramsConnectionUtmComponent},

  { path: 'search', component: SearchComponent},
  { path: 'devices', component: DevicesComponent},
  { path: 'programs', component: ProgrammsComponent},
  { path: 'developments', component: DevelopmentsComponent},
  { path: 'integrations', component: IntegrationsComponent},
  { path: 'contacts', component: ContactsComponent},
  
  { path: 'booking-module', component: BookingModuleComponent},
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
*/
