import { Component, Input } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';

@Component({ 
  selector: 'app-banner-partner',
  templateUrl: './banner-partner.component.html',
  styleUrls: ['./banner-partner.component.scss']
})
export class BannerPartnerComponent {
  @Input() item:any = {};  

  constructor( private openFormService: openFormService, private openFormData: openFormData){}

  onClickButton() {
    this.openFormService.backgroundTop2(); // Вызываем метод сервиса для изменения фона
  }
}  
 