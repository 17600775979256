<div class="wrapper" *ngIf="isVisible" [class]="bgOpen2"> <!-- обертка формы закрытие всей формы -->
   <div class="form-popup"> <!-- родительский компонент - форма -->
  
     <div  class="form-upper" *ngIf="showUpper"> <!-- обертка верхней формы с инпутами для закрытия формы при отправке добавить в тег - -->
       <app-modal-forms-upper (removeUpper)="onRemoveUpper()" (toggleEvent)="toggleComponents()" [item]="item"></app-modal-forms-upper> <!-- компонент формы с инпутами -->
       <div class="form-close" (click)="close()"></div> <!-- кнопка закрытия всей формы -->
     </div>
  
     <div class="form-lower" *ngIf="!showUpper">
       <app-modal-forms-lower (click)="close()"></app-modal-forms-lower> <!-- компонент формы с благодарностью при нажатии на весь компонент форма закроется -->
     </div>
   </div>
</div> 