import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-interval-average',
  templateUrl: './interval-average.component.html',
  styleUrls: ['./interval-average.component.scss']
})
export class IntervalAverageComponent {
@Input() item:any = {};
}
