import { Component, EventEmitter, OnInit, Input, Output, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true
    }
  ]
})
export class InputPhoneComponent implements ControlValueAccessor, Validator {
  onTouched: () => void = () => {};

  @Input() value: any = "";
  @Input() placeholder: string = "";
  @Input() required: boolean = false;
  @Input() type: string = "text";
  @Input() language: string = "ru";
  @Input() multiline: boolean = false;
  @Input() autocomplete: string = "";
  @Input() inputId: string = "";
  @Input() name: string = "";
  @Input() mask: string = "+7 (000) 000-00-00"; // Default mask
  @Output("onChange") textChange: EventEmitter<string> = new EventEmitter<string>();
  @Output("onInput") onInput = new EventEmitter<Event>();


  public validPhone:boolean = false;

  constructor() {}

  ngOnInit(): void {}

  writeValue(value: string): void {
    this.value = value;
    this.validateInput();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.textChange.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validateInput(): void {
    this.validPhone = (this.value.length >= this.mask.length);
    this.textChange.emit(this.value);
  }

  // Checks if the character at caret position is part of the mask
  isMaskCharacter(caret: number, character: string): boolean {
    try{
      const maskChar = this.mask[caret];
      if(maskChar === "0" && /[0-9]/.test(character)){
        return true;
      }else if(maskChar === "_"){
        return true;
      }
    }catch(e){
      console.log(e);
    }
    return false;
  }

  isValidCharacter(caret: number, character:string){
    const maskChar = this.mask[caret];
    if(maskChar === "0"){
      if(/[0-9]/.test(character)){
        return true;
      }
    }else if(maskChar === "_"){
      return true;
    }
    return false;
  }

  // Inserts mask at the specified position in the value
  applyMask(caret: number, value: string): string {
    const nextMaskChar = this.mask[caret];
    if (!nextMaskChar) 
      return value;
    if (nextMaskChar === "0" || nextMaskChar === "_") 
      return value;
    if (value.length === caret) 
      value += nextMaskChar;
    else 
      value = this.replaceAt(value, caret, nextMaskChar);
    return this.applyMask(caret + 1, value);
  }

  // Inserts character at the specified position in the value
  insertCharacter(element: any, value: string, caret: number, character: string): string {
    if(caret >= this.mask.length){
      return value;
    }
    const isMask = this.isMaskCharacter(caret, character);
    if (!isMask) return this.insertCharacter(element, value, caret + 1, character);
    if (value.length === caret) value += character;
    else value = this.replaceAt(value, caret, character);
    element.value = value;
    this.setCaretPosition(element, caret + 1);
    return value;
  }

  // Handles key press event
  onKeyPress(event: any): any {
    const value: string = event.target.value;
    const element = event.target;
    const character = event.key;
    const result = this.processKeyPress(element, character);
    if( result == false){
      return false;
    }
  }

  onChange(event:any){
    this.validPhone = (event.target.value.length >= this.mask.length);
    this.textChange.emit(event.target.value);
  }

  processKeyPress(element:any, character:string){

    const value: string = element.value;
    let caret = this.getInputSelection(element).start;

    if (this.mask.length > 0 && this.mask[caret] === undefined) {
      return false;
    }
      
    
    const newValue = this.applyMask(caret, value);
    if (newValue.length > value.length) {
      if(this.isValidCharacter(newValue.length, character) == false){
        element.value = newValue;
      }else{
        element.value = newValue + character;
      }
      return false;
    }

    if (newValue.length > caret && this.isMaskCharacter(caret, character)) {
      element.value = this.replaceAt(newValue, caret, character);
      this.setCaretPosition(element, caret + 1);
      return false;
    }

    if (newValue.length > caret && !this.isMaskCharacter(caret, character)) {
      element.value = this.insertCharacter(element, newValue, caret, character);
      return false;
    }

    if(this.isValidCharacter(caret, character) == false){
      return false;
    }
    element.value = newValue;

    this.textChange.emit(newValue);
    return true;
  }

  escapeRegExp(string:string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  replaceAll(str:string, find:string, replace:string) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }
  
  prepareOnPasteValue(value:string):string{
    value = this.replaceAll(value, " ", "");
    value = this.replaceAll(value, "(", "");
    value = this.replaceAll(value, ")", "");
    value = this.replaceAll(value, "-", "");
    value = this.replaceAll(value, ":", "");
    return value;
  }

  // Handles paste event
  onPaste(event: any): void {
    let value: string = this.prepareOnPasteValue(event.clipboardData.getData('text/plain'));
    const element = event.target;

    if(value.substring(0,1) == "8"){
      value = "+7" + value.substring(1, value.length);
    }
    console.log(value);

    const caret = this.getInputSelection(element).start;
    var newValue = element.value.substring(0, caret);
    console.log("caret", caret);
    console.log("this.mask.length", this.mask.length);
    console.log("value.length", value.length);
    



    for(let i = caret; i < this.mask.length; i++){


      const maskChar = this.mask[i];
      console.log("maskChar", maskChar);


      if(this.isMaskCharacter(i, value[i])){
        newValue += value[i];
      }else{
        newValue += maskChar;
      }

    }
    
    event.preventDefault();
    event.target.value = newValue;
    this.textChange.emit(newValue);
  }

  // Sets caret position in the input field
  setCaretPosition(ctrl: any, pos: any): void {
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
      const range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  // Gets current caret position in the input field
  getInputSelection(el: any): { start: number; end: number } {
    let start = 0, end = 0;
    if (typeof el.selectionStart === "number" && typeof el.selectionEnd === "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    }
    return { start, end };
  }

  // Replaces character at the specified index in the string
  replaceAt(source: string, index: number, replacement: string): string {
    return source.substring(0, index) + replacement + source.substring(index + replacement.length);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Если поле является обязательным, но пустое
    if (this.required && !this.value) {
      return { required: true };
    }
  
    // Если введенный номер телефона невалиден
    if (this.validPhone==false) {
      return { phoneInvalid: true };
    }
  
    // Если ошибок нет, возвращаем null
    return null;
  }

  get inputClasses() {
    return {
      'ng-invalid': this.required && !this.value || !this.validPhone,
      'ng-valid': this.validPhone && this.value,
    };
  }
}
