import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { VisibilityService } from '../../servises/Visibility.service'; // сервис обмена между компонентами авто закрытие формы
import { SiteService } from '../../site.service';
import { openFormService } from '../../servises/openForm.service';



@Component({ 
  selector: 'app-popup-form',
  templateUrl: './popup-form.component.html',
  styleUrls: ['./popup-form.component.scss']
})
export class PopupFormComponent {
  @Input() item: any = {};
  bgOpen2 = '';
  showUpper = true; // Изначально показываем верхнюю часть формы
  isVisible = false;
  subscription!: Subscription;

  constructor(private visibilityService: VisibilityService, public site:SiteService, private openFormService: openFormService) { 
    this.subscription = this.visibilityService.hideElement$.subscribe(() => {
      setTimeout(() => {
        this.isVisible = false;
      }, 7000); // Задержка в 1 секунду (1000 мс)
    });
  } 
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Отписываемся от подписки при уничтожении компонента
    }
  }


  ngOnInit(): void {
    this.openFormService.backgroundChanged2.subscribe(() => {
      this.isVisible = true; // Открываем форму
      this.bgOpen2 = 'bg-open2'; // Меняем класс
      this.showUpper = true; // Устанавливаем showUpper в true при каждом открытии формы
    });
  }

  toggleComponents() {
    this.showUpper = !this.showUpper; // Переключаем между верхним и нижним компонентами
  }

  onRemoveUpper() {
    this.showUpper = false; // Переходим к нижней части формы
  }

  close() {
    this.isVisible = false; // Закрываем всю форму
    this.bgOpen2 = ''; // Убираем класс
  }
}
