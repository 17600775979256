<div class="wrapper">
    
   
   <app-slider   [duration]="500" (onChange)="this.changeSlide($event)">

       <app-slide class="slide" *ngFor="let item of this.page.packages">  

         <div class="packet">
            <app-element-packets-mobile [item]="item" ngSkipHydration></app-element-packets-mobile>
         </div>
        </app-slide>   
           
 
   </app-slider> 

   <div class="block-progress"> 
      <div class="counter">
          <div class="counter_item" [ngClass]="getCounterColor(0)" (click)="setBaner(getPaginatedIndex(0))"></div>
          <div class="counter_item" [ngClass]="getCounterColor(1)" (click)="setBaner(getPaginatedIndex(1))"></div>
          <div class="counter_item" [ngClass]="getCounterColor(2)" (click)="setBaner(getPaginatedIndex(2))"></div>
        </div>
  </div>

 

</div>

 