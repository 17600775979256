<div class="wrappper-banner">
    <div class="text-block">
        <div class="text-conent-block">
           <div class="text-conent">
               <h3 class="text-conent__title">50 +   &nbsp; ПАРТНЕРОВ</h3>
               <p class="text-conent__subtitle">Уверенность  в результате -  <br> результат совместной работы</p>
           </div>
           <div class="text-conent">
               <h3 class="text-conent__title">250 +   &nbsp; ОТЕЛЕЙ</h3>
               <p class="text-conent__subtitle">От небольших семейных, до<br>отелей с 900+ номерами</p>
           </div>
        </div>
        <div class="text-conent-link"> 
            <!--<div class="contenier-link"> 
              
                Открытые проекты
              </a>
              <app-red-arrow></app-red-arrow>
            </div>-->
            <div class="text-conent-link-margin contenier-link">
                  <a class="link-content__href" href="partners">
                    <!--<img class="link-content__href-icon" alt="j"/>-->
                     Партнеры
                    
                  </a>
                  <a class="link-content__href" href="partners">
                  <app-red-arrow></app-red-arrow>
                  </a>
            </div>  
        </div>
    </div>
    <div [style.background-image]="this.background" class="image-block">  
        <div class="left">
           <div class="image-block__logo">
              <img class="image-block__img" src="../../../assets/main/Logo w.svg" />
              <h2 class="image-block__title">TRIPADVANCE</h2>
           </div>
           <div class="image-block__text-contenier">
              <p class="image-block__text-subtitle">Видения реализуются под многочисленными<br>взглядами. Наш успех начинается с вас</p>
           </div>
        </div>
        <div class="center">
            <img class="center-svg" src="../../../assets/main/Logo w.svg"/>
            <img class="center-svg-1c" src="../../../assets/main/1-c.svg"/>
        </div>
        <div class="riht">
            <div class="image-block__text-contenier-riht">
                <p class="image-block__text-subtitle">Расширение возможностей уже сегодня!<br>Индивидуальные разработки</p>
             </div>
        </div>
    </div>
 </div>

