import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ViewChildren, ContentChildren, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { SliderAnimationType } from '../ui/slider/slider.enum';
import { SliderComponent } from '../ui/slider/slider.component';
import { SlideComponent } from '../ui/slide/slide.component';

@Component({
  selector: 'app-main-baner',
  templateUrl: './main-baner.component.html',
  styleUrls: ['./main-baner.component.scss']
})
export class MainBanerComponent { 
  public currentSlide: number = 0;
  public countSlides: number = 3;
  public slides: string[] = []; 

  public animationType: SliderAnimationType = SliderAnimationType.EaseInOutSine;
  public animationDuration: number = 700;

  @ViewChild(SliderComponent) slider!: SliderComponent;
  @ContentChildren(SlideComponent, { read: ElementRef }) elSliders!: any;
  @ViewChild("progressBar", { static: false }) progressBar!: ElementRef;

  public paginatePage: number = 0;

  constructor(private renderer: Renderer2, private cdref: ChangeDetectorRef) {}

  ngOnInit() {
    for (let i = 0; i < this.countSlides; i++) {
      this.slides.push(`Slide ${i + 1}`);
    }
    
    // Убедимся, что количество слайдов кратно трем
    if (this.countSlides % 3 !== 0) {
      const missingSlides = 3 - (this.countSlides % 3);
      for (let i = 0; i < missingSlides; i++) {
        this.slides.push('');
      }
      this.countSlides += missingSlides;
    }
  }

  ngAfterViewInit(): void {
    this.slider.autoPlay(true);
    this.countSlides = this.elSliders.length;
  }

  ngAfterContentInit(): void {}

  getCurrentSlide() {
    return this.currentSlide;
  }

  getCountSlides() {
    return this.countSlides;
  }

  getCounterColor(paginatedIndex: number) {
    const realIndex = this.getPaginatedIndex(paginatedIndex);
    return {
      'active': this.currentSlide === realIndex,
      'inactive': this.currentSlide !== realIndex
    };
  }

  getPaginatedIndex(paginatedIndex: number): number {
    return this.paginatePage * 3 + paginatedIndex;
  }
 
  updatePagination(currentSlide: number) {
    this.paginatePage = Math.floor(currentSlide / 3);
  }

  changeSlide(event: any) {
    this.currentSlide = event.current;
    this.updatePagination(this.currentSlide);
  }

  setBaner(value: any) {
    this.slider.setSlide(value);
  }

  /*sliderAnimate(event: any) {
    var width = event.durationPercent;
    this.renderer.setStyle(this.progressBar.nativeElement, 'width', `${width}%`);
  }*/
}
