
<div class="wrapp">

    <div class="left">
       <div class="tab" *ngFor="let item of this.products" (click)="onChange(item)" [class.actual]="(item==this.currentProduct)">
          <p class="title-product-left">{{item.button}}</p>
       </div>
    </div>   
    <div class="right">
      <!--стиль списка продуктов с ценами
       <div class="products-contenier" *ngFor="let  product of this.currentProducts">
           <p class="title-product product-discription">{{product.name}}</p>
           <div class="line-vertical"></div>
           <div class="price-link-contenier">
             <p class="product-price">{{product.price}}₽</p>
             <div class="button-contenier">
                <app-button>ЗАКАЗАТЬ</app-button>
             </div>
           </div>

           <div class="products-contenier2">
              <div class="img-content">
                <div class="block-baner__container" [style.background-image]="this.currentProducts.image"></div>
              </div>
           </div>

       </div>-->
   
       <div class="products-contenier" *ngFor="let  product of this.currentProducts">
         <div class="products-contenier__left">
            <div class="block-baner__container">
               <div class="block-baner__img">
                  <img class="image" [attr.src]="product.image"/>
               </div>
            </div>
            <div class="line-vertical__container">
               <div class="line-vertical__big"></div>
            </div>
         </div>
         <div class="products-contenier__riht">
            <h3 class="title-product">{{product.name}}</h3>
            <p class="product-discription">{{product.description}}</p>
            <p class="product-discription">{{product.description2}}</p>
            <p class="product-discription">{{product.description3}}</p>
            <p class="product-discription">{{product.description4}}</p>
            <div class="prise-contenier">
               <p class="product-price">{{product.price}}₽</p>
               <div class="line-vertical"></div>
               <app-button ngSkipHydration class="button-link" (click)="this.onClickButton()">ОФОРМИТЬ</app-button>
            </div>
         </div>
       </div> 
    

    </div>