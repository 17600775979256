import { Component } from '@angular/core';

@Component({
  selector: 'app-interval-big',
  templateUrl: './interval-big.component.html',
  styleUrls: ['./interval-big.component.scss']
})
export class IntervalBigComponent {

}
