import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../site.service';
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';

interface Page {
  formContent?: {
    data?: string,
    top?: string 
  };
  formContent2?: { 
    data?: string,
    top?: string
  };
} 
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html', 
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  bgOpen = ''; 
  bgOpenTwo = ''; 
  page: Page = {};

  constructor(private siteService: SiteService, private openFormService: openFormService, private openFormData: openFormData) {}

  ngOnInit(): void {
    this.page = this.siteService.GetCurrentPage2('home');

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
    });

    this.openFormData.backgroundChanged.subscribe(() => {
      this.bgOpenTwo = this.bgOpenTwo  ? '' : 'bg-open2'; // Меняем класс
    });
    
    this.getPageData(); 
  }

  getPageData() {
    this.page.formContent = this.page.formContent ?? {};
    this.page.formContent.data = '';
    this.page.formContent.top = '';
  }
  closeForm() {
    this.bgOpen = ''; // Убираем класс bg-open
  }
}
