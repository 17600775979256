import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-services-programming-licenzii',
  templateUrl: './services-programming-licenzii.component.html',
  styleUrls: ['./services-programming-licenzii.component.scss']
})
export class ServicesProgrammingLicenziiComponent {
  page:any = {};
  bgOpen = ''; 

  constructor(public site:SiteService, private openFormService: openFormService){
    this.page = this.site.GetCurrentPage();
    console.log(this.page); 
  }

  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
    });

  }
}
