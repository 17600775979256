import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ViewChildren, ContentChildren, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { SliderAnimationType } from '../../ui/slider/slider.enum';
import { SliderComponent } from '../../ui/slider/slider.component';
import { SlideComponent } from '../../ui/slide/slide.component';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent {  
  @Input() item:any = {};
  page:any = {};

  @Input() products:string = "";
  productsItems: any = [];

  public currentSlide: number = 0; 
  public countSlides: number = 3;
  public slides: string[] = [];
  public paginatePage: number = 0; 

  @ViewChild(SliderComponent) slider!: SliderComponent;
  @ContentChildren(SlideComponent, { read: ElementRef }) elSliders!: any;
  public animationType: SliderAnimationType = SliderAnimationType.EaseInOutSine;

  clientWidth: number = 0;
  @ViewChild("progressBar", { static: false }) progressBar!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: object, public site:SiteService, private route: ActivatedRoute){
    if (isPlatformBrowser(this.platformId)) {
      this.clientWidth = document.body.clientWidth;
    }
  }

  

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.clientWidth = document.body.clientWidth;
    }
  }

  ngOnInit() {
    this.productsItems = (this.site as any)[this.products];
    for (let i = 0; i < this.countSlides; i++) {
      this.slides.push(`Slide ${i + 1}`);
    }
    
    // Убедимся, что количество слайдов кратно трем
    if (this.countSlides % 3 !== 0) {
      const missingSlides = 3 - (this.countSlides % 3);
      for (let i = 0; i < missingSlides; i++) {
        this.slides.push('');
      }
      this.countSlides += missingSlides;
    }
  }

  ngAfterViewInit(): void {
    this.countSlides = this.elSliders.length;
  }
  ngAfterContentInit(): void {}
  getCurrentSlide() {
    return this.currentSlide;
  }
  getCountSlides() {
    return this.countSlides;
  }
  getCounterColor(paginatedIndex: number) {
    const realIndex = this.getPaginatedIndex(paginatedIndex);
    return {
      'active': this.currentSlide === realIndex,
      'inactive': this.currentSlide !== realIndex
    };
  }
  getPaginatedIndex(paginatedIndex: number): number {
    return this.paginatePage * 3 + paginatedIndex;
  }
  updatePagination(currentSlide: number) {
    this.paginatePage = Math.floor(currentSlide / 3);
  }

  changeSlide(event: any) {
    this.currentSlide = event.current;
    this.updatePagination(this.currentSlide);
  }

  productNext(){
    this.slider.next();
  }

  productPrev(){
    this.slider.prev(); 
  }

  isMobile(){
    var mobile = (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/BlackBerry/i)||
            navigator.userAgent.match(/iPhone|iPad|iPod/i)||
            navigator.userAgent.match(/Opera Mini/i)||
            navigator.userAgent.match(/IEMobile/i));

    return mobile;
  }

  getCountProductView(){
    if(this.clientWidth <= 850)
      return 1;
    if(this.clientWidth <= 1200)
      return 2;
    return 3;
  }

  ///элеменнт связывающий пагинацию
  setBaner(value:any){
    this.slider.setSlide(value);
  }
}
