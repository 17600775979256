import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent { 
  page:any = {};
  bgOpen = ''; 
  bgOpenTwo = ''; 
  bgOpen3 = ''; 
  
  constructor(public site:SiteService, private route: ActivatedRoute, private openFormService: openFormService, private openFormData: openFormData){

  }

  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
    });

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpen3 = this.bgOpen3 ? '' : 'bg-open3'; // Меняем класс
    });

    this.openFormService.backgroundChanged.subscribe(() => {
      this.bgOpenTwo = this.bgOpenTwo  ? '' : 'bg-open2'; // Меняем класс
    });
  }

  ngAfterContentInit(){
  }
}
