import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleService {
  private _backgroundChangedSubject = new BehaviorSubject(false);
  public backgroundChanged = this._backgroundChangedSubject.asObservable();

  private _redAnimationSubject = new BehaviorSubject(false);
  public redAnimationChanged = this._redAnimationSubject.asObservable();

  constructor() {}

  changeBackground() {
    this._backgroundChangedSubject.next(true);
  }

  triggerRedAnimation() {
    this._redAnimationSubject.next(true);
  }

   // Новый метод для управления последовательностью событий
   showLowerThenAnimate() {
    this.changeBackground(); // Сначала показываем нижний компонент
    setTimeout(() => {
      this.triggerRedAnimation(); // Затем добавляем анимацию
    }, 100); // Задержка перед анимацией (в миллисекундах)
  }
}