<div class="wrapp">
    <div class="button desktop">
       <app-mini-grin-arrow  (onClick)="($event == 1) ? this.productNext() : this.productPrev()">
       </app-mini-grin-arrow > 
    </div>

    <div class="products" *ngIf="products!=''">
        <app-slider [type]="this.animationType" [countView]="this.getCountProductView()" [duration]="500" (onChange)="this.changeSlide($event)">
            
            <app-slide  *ngFor="let item of this.productsItems"> 
                <div class="wrapp-product">
                    <app-element-product [item]="item"></app-element-product>
                </div> 
               
            </app-slide> 

                        
        </app-slider> 

        <div class="block-progress mobile"> 
            <div class="counter">
               <div class="counter_item" [ngClass]="getCounterColor(0)" (click)="setBaner(getPaginatedIndex(0))"></div>
               <div class="counter_item" [ngClass]="getCounterColor(1)" (click)="setBaner(getPaginatedIndex(1))"></div>
               <div class="counter_item" [ngClass]="getCounterColor(2)" (click)="setBaner(getPaginatedIndex(2))"></div>
            </div>
        </div>

    </div> 
</div>    