import { Component } from '@angular/core';

@Component({
  selector: 'app-main-mobile-cards',
  templateUrl: './main-mobile-cards.component.html',
  styleUrls: ['./main-mobile-cards.component.scss']
})
export class MainMobileCardsComponent {

}
