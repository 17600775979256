<div class="wrapper">
    <div class="top top-img">

    </div>
    <div class="bottom">
        <p class="bottom__txt">Разработанное нами расширение для программы 1С: Отель, уже помогает нашим клиентам в автоматическом контроле, бронированию и учету парк. мест в отелях</p>
        <div class="wrapp-button">
          <!-- <a href="">
               <app-button  ngSkipHydration>ПОДРОБНЕЕ</app-button>
            </a>--> 
        </div>
    </div>
</div>
<div class="wrapper">
    <div class="top top-img1">

    </div>
    <div class="bottom">
        <p class="bottom__txt">Работая в программе 1С: Отель, у специалистов есть возможность отправить данные сразу в Битрикс 24 и уже в CRM системе управлять сделкой по воронкам продаж</p>
        <div class="wrapp-button">
               <!-- <a href="">
               <app-button  ngSkipHydration>ПОДРОБНЕЕ</app-button>
            </a>--> 
        </div>
    </div>
</div>
<div class="wrapper">
    <div class="top top-img2">

    </div>
    <div class="bottom">
        <p class="bottom__txt">Близится большое запланированное обновление (v2.1) к своему завершению. С небольшим запозданием, но уже совсем скоро - новый дизайн и возможности модуля бронирования</p>
        <div class="wrapp-button">
               <a href="developments/booking-module">
               <app-button  ngSkipHydration>ПОДРОБНЕЕ</app-button>
            </a>
        </div>
    </div>
</div>