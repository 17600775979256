import { Component, Input } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-element-slides-bottom-animation',
  templateUrl: './element-slides-bottom-animation.component.html',
  styleUrls: ['./element-slides-bottom-animation.component.scss']
})
export class ElementSlidesBottomAnimationComponent {
  @Input() item: any = {};

   constructor( private openFormService: openFormService){}

  onClickButton() {
    this.openFormService.backgroundTop(); // Вызываем метод сервиса для изменения фона
  }
}
