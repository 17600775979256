import { Component, Input } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-element-card-marketing',
  templateUrl: './element-card-marketing.component.html',
  styleUrls: ['./element-card-marketing.component.scss']
})
export class ElementCardMarketingComponent {
  @Input() item:any = {};

  constructor( private openFormService: openFormService){}

  onClickButton() {
    this.openFormService.backgroundTop(); // Вызываем метод сервиса для изменения фона
 } 
 onClickButton2() {
  this.openFormService.backgroundTop2(); // Вызываем метод сервиса для изменения фона
} 
 onClickButton3() {
  this.openFormService.backgroundTop3(); // Вызываем метод сервиса для изменения фона
} 
}
