import { Component, Input, ContentChildren } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-element-packets-mobile',
  templateUrl: './element-packets-mobile.component.html',
  styleUrls: ['./element-packets-mobile.component.scss']
})
export class ElementPacketsMobileComponent {
 @Input() item:any = [];
  
  constructor( private openFormService: openFormService){}

 changeBlock(blocks:any, item:any){
  for(var i = 0; i < blocks.length; i++){
    if(blocks[i] == item){
      blocks[i].open = !blocks[i].open;
    }else
      blocks[i].open = false;
  }
}

onClickButton() {
  this.openFormService.backgroundTop(); // Вызываем метод сервиса для изменения фона
}
}
